import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import ClientService from "@/services/ClientService";
import { IClient } from "@/models/IClient";
import { Watch } from "vue-property-decorator";
import moment from "moment";



Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

@Options({
  components: {
    AdminLayout,

  },

  methods: {
    handleSort(key, active) {
      alert(key + " " + active)
    },
  },
})
export default class ClientModule extends Vue {
  clients: IClient[] = []
  isloaded = false
  selected = []
  popupActivo = false
  totalItems = 0
  current_page = 1
  search: string = ""


  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };
  headers = [
    {
      key: "clientName",
      label: "Client Name",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "phone",
      label: "Phone",
    },
    {
      key: "address",
      label: "Address",
    },
    {
      key: "packageName",
      label: "Package",
    },
    {
      key: "isActive",
      label: "Status",
    },
    {
      key: "createdDate",
      label: "Created Date",
    },
  ];


  handleChangePage(page) {
    this.table.page = page - 1;
    //this.isloaded = false;
    this.setTable(this.table.page);
  }

  async created(){
    var result = await ClientService.getListofClient(1)
    this.clients = result.items
    this.totalItems = result.totalPages
    console.log(this.clients)
  }

  async setTable(pagenumber:number)
  {
    var result = await ClientService.getListofClient(pagenumber,this.search)
    this.clients = result.items
   // this.totalItems = result.totalCount
    //console.log(this.clients)
   // this.totalItems = this.clients.length;
  }

  @Watch("clients")
  onPropertyChanged(value: string, oldValue: string) {
    this.isloaded = true
   // console.log(this.clients)
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false
    await this.setTable(this.current_page)
    //console.log(this.current_page)
  }

  openModal(clientId:number)
  {
    this.popupActivo=true
  }

  async handleSearch(searching) {
    if (searching.length > 3)
    {
      this.search = searching
      var result = await ClientService.getListofClient(1,this.search)
      this.clients = result.items
    }
    else if(searching.length == 0)
    {
      var result = await ClientService.getListofClient(1)
      this.clients = result.items
    }
  }

  openEidtPage(clientId:number)
  {
    this.$router.push({ path: '/admin/addclient', query: { cId: clientId.toString() }});
  }
}
